export const columnDropdown = [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80},
      {key: '2', headerName: 'Short Name', field: 'ddShortName', hide: false, width: 120},
      {key: '3', headerName: 'Dropdown Name', field: 'dropdownName', hide: false, width: 280},
      {key: '4', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 160},
  ];
  
  export const columnDropdownList = [
    {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80},
    {key: '2', headerName: 'Dropdown Name', field: 'dropDownName', hide: false, width: 240},
    {key: '2', headerName: 'List Short Name', field: 'listShortName', hide: false, width: 150},
    {key: '2', headerName: 'Dropdown List Name', field: 'dropdownListName', hide: false, width: 280},
    {key: '5', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 160},
];
