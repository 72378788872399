export const keys = Object.freeze({
  signIn: 'signIn',
  // authenticate: 'authenticate',
  deleteSelectedEmployees: "deleteSelectedEmployees",
  deleteEmployeeMaster: "deleteEmployeeMaster",
  saveEmployeeMaster: "saveEmployeeMaster",
  employeeMaster: "employeeMaster",
  getEditEmployeeMaster: 'getEditEmployeeMaster',
  getEditUserMaster: 'getEditUserMaster',

  userMaster: "userMaster",
  saveUserMaster: 'saveUserMaster',
  updateUserMaster: 'updateUserMaster',

  primaryCustomer: "primaryCustomer",
  getPrimeCustDataFromSap: "getPrimeCustDataFromSap",
  primeCustListBySubzone: (subzone) => `primeCustListBySubzone/${subzone}`,
  savePrimaryCustomerMaster : 'savePrimaryCustomerMaster',
  updatePrimaryCustomerMaster : 'updatePrimaryCustomerMaster',
  deletePrimaryCustomerMaster : 'deletePrimaryCustomerMaster',
  getEditPrimaryCustomerMaster : 'getEditPrimaryCustomerMaster',
  outletMaster: 'outletMaster',

  //Student
  addAdmissionMasterData: 'addAdmissionMasterData',
  admissionMasterData: 'admissionMasterData',
  classAllotmentData: 'classAllotmentData',
  addEnquiryMasterData: 'addEnquiryMasterData',
  updateEnquiryMasterData: 'updateEnquiryMasterData',
  enquiryMasterData: 'enquiryMasterData',
  timeTableMasterData: 'timeTableMasterData',


  //fees management
  feesTypeData: 'feesTypeData',
  feesCategoryData: 'feesCategoryData',
  feesNameData: 'feesNameData',
  feesAllotmentData: 'feesAllotmentData',

  academicMasterData: 'academicMasterData',
  countryMasterData: 'countryMasterData',
  designationMasterData: 'designationMasterData',
  getMenuMasterData: 'getMenuMasterData',
  getMenuListData: 'getMenuListData',
  getMenuAccessData: 'getMenuAccessData',
  districtMasterData: 'districtMasterData',
  dropdownMasterData: 'dropdownMasterData',
  dropdownListMasterData: 'dropdownListMasterData',
  roleMasterData: 'roleMasterData',
  stateMasterData: 'stateMasterData',
  standardMasterData: 'standardMasterData',
  standardOptions: 'standardOptions',
  sectionMasterData: 'sectionMasterData',
  subjectMasterData: 'subjectMasterData',
  termMasterData: 'termMasterData',








 
});
