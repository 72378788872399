import React, {  } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { column } from './column';
import UseStaffMaster from './UseStaffMaster';


const StaffMaster = ({ setTopTitle }) => {
  
    setTopTitle('Staff Master');

  const {
    onClickAdd,
    handleEditClick,
    enquiryMasterLoading,
    enquiryMasterValues
  } = UseStaffMaster();

  return (
    <div className="h-screen">
      <CustomTable
        dataSource={enquiryMasterValues}
        loading={enquiryMasterLoading}
        column={column}
        handleEditClick={handleEditClick}
        onClickAdd={onClickAdd}
        title={"Staff Master List"}
      />
    </div>
  );
}

export default StaffMaster;
