import { useMemo, useEffect, useState, } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, } from "../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';
import useGetDropdownListById from '../../../common/hooks/useGetDropdownListById';

export default function useStandardMaster() {

  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {DropdownValue, getDropdownData} = useGetDropdownListById();
 const [selectedValues, setSelectedValues] = useState([]);


  const feesCategoryValues = getData(state, apiKeys.feesCategoryData, []);
  const feesNameValues = getData(state, apiKeys.feesNameData, []);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.feesCategoryData,
      method: apiMethods.GET,
      key: apiKeys.feesCategoryData,
    }))
  }, [dispatch,]);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.feesNameData,
      method: apiMethods.GET,
      key: apiKeys.feesNameData,
    }))
  }, [dispatch,]);

  useEffect(() => {
    getDropdownData(12);
  }, [getDropdownData]);

  const handleFormSubmit = () => {
    dispatch(fetchData({
      url: apiUrls.feesNameData,
      method: apiMethods.POST,
      key: apiKeys.feesNameData,
      body: { dropdownId: selectedValues }
    }));
  };

  const handleChange = (selectedValues) => {
    setSelectedValues(selectedValues);
  };

  const onClickAddStd = () => {
    navigate('/outletMaster/addForm');
  };

  const handleEditClickStd = (data) => {
    navigate('/outletMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  const onClickAddSec = () => {
    navigate('/outletMaster/addForm');
  };


  const handleEditClickSec = (data) => {
    navigate('/outletMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  return useMemo(() => ({
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    feesCategoryValues,
    DropdownValue,
    handleFormSubmit,
    feesNameValues,
    handleChange
  }), [
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    feesCategoryValues,
    DropdownValue,
    handleFormSubmit,
    feesNameValues,
    handleChange

  ]
  )

}