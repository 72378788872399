import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';
import { useDispatch, useSelector } from 'react-redux';
import { getData, } from "../../../@app/slices/helpers/helpers";
import { useMemo, useState, useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router';

export default function useMenuAccessForm() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const { state: locationState } = useLocation(); // Rename state to locationState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const isEdit = locationState?.isEdit || false;
  let defaultValue = locationState?.data;

  const menuAccessData = getData(state, apiKeys.getMenuAccessData, []);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.getMenuAccessData,
      method: apiMethods.GET,
      key: apiKeys.getMenuAccessData,
    }))
  }, [dispatch,]);

  const onFinish = (values) => {
    setShowDialog(false);
    let data = {
      menuName: values.userName,
      menuAccessData: values.menuAccessData,
      status: values.status === 'active' ? 1 : 0,
    };

    dispatch(fetchData({
      url: apiUrls.dropdownListMasterData,
      method: apiMethods.POST,
      key: apiKeys.dropdownListMasterData,
      body: { data }
    }));

  }

  const handleClickBack = () => {
    navigate('/menuMaster');
  };

  const handleEditClickAccess = (data) => {
    navigate('/menuAccessMaster/addForm', {
      state: { data, isEdit: true }
    });
  };


  return useMemo(() => ({
    onFinish,
    menuAccessData,
    showDialog,
    handleClickBack,
    handleEditClickAccess,
    defaultValue,
    isEdit
  }), [ onFinish,
    menuAccessData,
    showDialog,
    handleClickBack,
    handleEditClickAccess,
    defaultValue,
    isEdit]);
}