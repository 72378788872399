import React, {  } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { column } from './column';
import UseSubjectMaster from './UseSubjectMaster';


const SubjectMaster = ({ setTopTitle }) => {
  
    setTopTitle('Subject Master');

  const {
    onClickAdd,
    handleEditClick,
    subjectMasterValues
  } = UseSubjectMaster();

  return (
    <div className="h-screen pad-lg">
    
      <CustomTable
        dataSource={subjectMasterValues}
        column={column}
        handleEditClick={handleEditClick}
        onClickAdd={onClickAdd}
        title={"Subject Master List"}
      />
    </div>
  );
}

export default SubjectMaster;
