export const columnCategory = [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80},
      {key: '2', headerName: 'Fees Category', field: 'dropdownListName', hide: false, width: 180},
      {key: '5', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 150},
  ];
  
  export const columnName = [
    {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80},
    {key: '2', headerName: 'Fees Category', field: 'feesCategory', hide: false, width: 250},
    {key: '2', headerName: 'Fees Name', field: 'feesName', hide: false, width: 250},
    {key: '5', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 150},
];
