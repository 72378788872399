import React, {  } from 'react';
import CustomTable from '../../../common/components/CustomTimeTable';
import { column } from './column';
import UseTimeTableMaster from './UseTimeTableMaster';


const TimeTableMaster = ({ setTopTitle }) => {
  
    setTopTitle('Time Table');

  const {
    onClickAdd,
    handleEditClick,
    timeTableMasterLoading,
    timeTableMasterValues
  } = UseTimeTableMaster();

  return (
    <div className="h-screen">
      <CustomTable
        dataSource={timeTableMasterValues}
        loading={timeTableMasterLoading}
        column={column}
        handleEditClick={handleEditClick}
        onClickAdd={onClickAdd} 
        title={"Time Table"}
      />
    </div>
  );
}

export default TimeTableMaster;
