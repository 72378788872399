import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { columnRole, columnDesignation } from './column';
import UseRoleMaster from './UseRoleMaster';
import { Tabs, Col,  } from "antd";

const RoleMaster = ({ setTopTitle }) => {
  const { TabPane } = Tabs;

  useEffect(() => {
    setTopTitle('Role Master');
  }, [setTopTitle]);

  const {
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    roleMasterValues,
    designationMasterValues
  } = UseRoleMaster();

  return (
    <>
      <div className='pad-lg'>
        <Col span={"24"}>
          <Tabs
            centered
            type='card'
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="Role"
              key="3"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={roleMasterValues}
                column={columnRole}
                handleEditClick={handleEditClickStd}
                onClickAdd={onClickAddStd}
                title={"Role Master List"}
              />
            </TabPane>
            <TabPane
              tab="Designation"
              key="4"
            >
              <CustomTable
                dataSource={designationMasterValues}
                column={columnDesignation}
                handleEditClick={handleEditClickSec}
                onClickAdd={onClickAddSec}
                title={"Role Master List"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>

    </>
  );
}

export default RoleMaster;
