import React from 'react';
import { Card, Button, Radio, Col, Row, Form, Upload, Modal, Image } from 'antd';
import ConfirmOnExit from '../../../common/components/confirmOnExit/ConfirmOnExit';
import UseEnquiryMasterForm from './UseEnquiryMasterForm';
import useStudentImageAdd from './UseStudentImageAdd';
import CustomDropdown from '../../../common/components/Dropdown/dropdown';
import useGetDropdownListOptions from '../../../common/hooks/useGetDropdownListMaster';
import useGetStandardOptions from '../../../common/hooks/useGetStandardMaster';
import useGetAcademicOptions from '../../../common/hooks/useGetAcademicMaster';
import CustomDatePicker from '../../../common/components/DateInput/dateInput';
import CustomInput from '../../../common/components/TextInput/textInput';


function EnquiryMasterForm({ setTopTitle }) {
  setTopTitle('Enquiry Form');
  const { enquiryType, enquiryFor, enquiryBy } = useGetDropdownListOptions();
  const { getStandardOptionData } = useGetStandardOptions();
  const { getAcademicYear } = useGetAcademicOptions();

  const {
    getFile, previewImage, previewOpen, previewTitle, fileList, handleChange, handleCancel,
    handlePreview, uploadButton, props, image } = useStudentImageAdd();
  const {
    onFinish, showDialog, handleClickBack, isEdit, selectedEnquiryType, selectedEnquiryFor,
    defaultValue, selectedStandardOption, selectedAcademicYear, selectedEnquiryBy,
    form, handleEnyType, handleEnyFor, handleEnyBy, handleEnyStd, handleAcdYear,
    disabledFutureDates } = UseEnquiryMasterForm(image);

  return (
    <>
      <Card>
        <ConfirmOnExit showModel={showDialog} />
        <Row>
          <Col span={24}>
            <Form
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{
                id: defaultValue?.id,
                enqType: defaultValue?.enqType,
                enqFor: defaultValue?.enqFor,
                enquiryStd: defaultValue?.enquiryStd,
                academicYear: defaultValue?.academicYear,
                enqBy: defaultValue?.enqBy,
                referredBy: defaultValue?.referredBy,
                studentName: defaultValue?.studentName,
                enquiryDate: defaultValue?.enquiryDate,
                parentName: defaultValue?.parentName,
                parentNumber: defaultValue?.parentNumber,
                referredNo: defaultValue?.referredNo,
                sibling: defaultValue?.sibling === 'Yes' ? 'Yes' : 'No',
                activeStatus: defaultValue?.activeStatus === 'inActive' ? 'inActive' : 'active',
              }}
              onFinish={onFinish}
              autoComplete='off'
              form={form}>
              <Row gutter={[15, 0]}>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='enqType' label='Enquiry Type'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomDropdown
                      options={enquiryType}
                      onChange={handleEnyType}
                      value={selectedEnquiryType || enquiryType.find(item => item.value === defaultValue?.enqType)}
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='enqFor' label='Enquiry For'
                    rules={[{ required: true, message: 'Please input the subject code!' }]} >
                    <CustomDropdown
                      options={enquiryFor}
                      onChange={handleEnyFor}
                      value={selectedEnquiryFor} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="multiple"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='enquiryStd' label='Enquiry Standard'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomDropdown
                      options={getStandardOptionData}
                      onChange={handleEnyStd}
                      value={selectedStandardOption} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='academicYear' label='Academic Year'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomDropdown
                      options={getAcademicYear}
                      onChange={handleAcdYear}
                      value={selectedAcademicYear} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Student Name' name='studentName'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomInput placeholder='Student Name' name='studentName' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Parent / Guardian Name' name='parentName'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomInput placeholder='Parent / Guardian Name' name='parentName' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Parent Number' name='parentNumber'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomInput placeholder='Parent Number' name='parentNumber' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='enqBy' label='Enquiry By'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}  >
                    <CustomDropdown
                      options={enquiryBy}
                      onChange={handleEnyBy}
                      value={selectedEnquiryBy} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item name="enquiryDate" label="Enquiry Date" rules={[{ required: true, message: 'Please input the enquiry date!' }]}>
                    <CustomDatePicker
                      value={defaultValue?.enquiryDate} // Ensure this value is a valid date string ('YYYY-MM-DD')
                      onChange={(dateString) => form.setFieldsValue({ enquiryDate: dateString })} // Ensure form updates correctly
                      disabledDate={disabledFutureDates} // Pass the disabled date function if needed
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item name='sibling' label='Sibling'
                    rules={[{ required: true, message: 'Please select your sibling' }]}>
                    <Radio.Group buttonStyle='solid' size='middle' name='sibling' >
                      <Radio.Button className='active' value='Yes'>
                        Yes
                      </Radio.Button>
                      <Radio.Button className='in-active' value='No'>
                        No
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Referred By' name='referredBy'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomInput placeholder='Referred By' name='referredBy' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Referred No' name='referredNo'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomInput placeholder='Referred No' name='referredNo' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item
                    name='studentImage'
                    label='Student Image'
                    getValueFromEvent={getFile}
                  >
                    <div style={{ display: 'flex', direction: 'col' }}>
                      {defaultValue?.id && fileList?.length === 0 ? (
                        defaultValue?.image ? (
                          <Image style={{ paddingRight: '10px' }} width={100} src={defaultValue?.image ?? ''} />
                        ) : (
                          'No Image Available'
                        )
                      ) : (
                        <></>
                      )}
                      <Upload
                        {...props}
                        fileList={fileList}
                        onPreview={handlePreview}
                        capture='environment'
                        accept='.png,.jpg,.jpeg'
                        onChange={(e) => {
                          handleChange(e);
                        }}>
                        {fileList.length >= 1 ? null : uploadButton}
                      </Upload>
                    </div>
                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                        alt='example'
                        style={{
                          width: '100%'
                        }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item name='activeStatus' label='Status'
                    rules={[{ required: true, message: 'Please select your status' }]}>
                    <Radio.Group buttonStyle='solid' size='middle' name='activeStatus' >
                      <Radio.Button className='active' value='active'>
                        Active
                      </Radio.Button>
                      <Radio.Button className='in-active' value='inActive'>
                        In-Active
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[15, 15]} style={{ justifyContent: 'end' }}>
                    <Col span={12} style={{ textAlign: 'right' }} className='d-flex align-items-center justify-content-end mt-3'>
                      <Form.Item className='mx-2'>
                        <Button className='orangeFactory' type='primary' htmlType='submit'>
                          {isEdit ? 'Update' : 'Add'}
                        </Button>
                      </Form.Item>
                      {/* </Col>
                    <Col span={12}> */}
                      <Form.Item>
                        <Button onClick={handleClickBack}>Back</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default EnquiryMasterForm;
