import { useMemo, useEffect, } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, } from "../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';

export default function useMenuMaster() {

  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const menuValues = getData(state, apiKeys.getMenuMasterData, []);
  const screenValues = getData(state, apiKeys.getMenuListData, []);
  const accessValues = getData(state, apiKeys.getMenuAccessData, []);


  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.getMenuMasterData,
      method: apiMethods.GET,
      key: apiKeys.getMenuMasterData,
    }))
  }, [dispatch,]);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.getMenuListData,
      method: apiMethods.GET,
      key: apiKeys.getMenuListData,
    }))
  }, [dispatch,]);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.getMenuAccessData,
      method: apiMethods.GET,
      key: apiKeys.getMenuAccessData,
    }))
  }, [dispatch,]);

  const onClickAddMenu = () => {
    navigate('/menuMaster/addForm');
  };

  const handleEditClickMenu = (data) => {
    navigate('/menuMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  const onClickAddList = () => {
    navigate('/menuListMaster/addForm');
  };

  const handleEditClickList = (data) => {
    navigate('/menuListMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  const onClickAddAccess = () => {
    navigate('/menuAccessMaster/addForm');
  };


  const handleEditClickAccess = (data) => {
    navigate('/menuAccessMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  return useMemo(() => ({
    onClickAddMenu,
    handleEditClickMenu,
    onClickAddList,
    handleEditClickList,
    onClickAddAccess,
    handleEditClickAccess,
    menuValues,
    screenValues,
    accessValues
  }), [
    onClickAddMenu,
    handleEditClickMenu,
    onClickAddList,
    handleEditClickList,
    onClickAddAccess,
    handleEditClickAccess,
    menuValues,
    screenValues,
    accessValues

  ]
  )

}