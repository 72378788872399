import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getStatus } from "../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';
import navs from '../../../api/constants/navs'

export default function useEnquiryMaster() {
  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  const timeTableMasterValues = getData(state, apiKeys.timeTableMasterData, []);
  const timeTableMasterStatus = getStatus(state, apiKeys.timeTableMasterData, '');
  const timeTableMasterLoading = apiStatuses.pending === timeTableMasterStatus;

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.timeTableMasterData,
      method: apiMethods.GET,
      key: apiKeys.timeTableMasterData,
    }));
  }, [dispatch]);

  const onClickAdd = () => {
    navigate(navs.timeTableMasterForm, {
      state: { isEdit: false }
    });
  };

  const handleEditClick = (data) => {
    navigate(navs.enquiryMasterForm, {
      state: { data, isEdit: true }
    });
  };

  return useMemo(() => ({
    onClickAdd,
    handleEditClick,
    timeTableMasterLoading,
    timeTableMasterValues
  }), [onClickAdd, handleEditClick, timeTableMasterLoading,
    timeTableMasterValues]);
}
