import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import Container from 'react-bootstrap/Container';
import InputAdornment from '@mui/material/InputAdornment';
import { SearchOutlined } from '@ant-design/icons';
import { OutlinedInput, Stack, Tooltip } from '@mui/material';
import Button from 'react-bootstrap/Button';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import { FaEye, FaUserEdit } from 'react-icons/fa';
import { useDebounce } from '../../customHooks/useDebouce';
import CustomPagination from './customPagination';
import { isEmpty } from 'ramda';

const CustomCheckboxTable = ({
  dataSource,
  column,
  title,
  loading = false,
  onClickAdd,
  handleEditClick,
  handleViewClick,
  addButtonStatus = false
}) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const debouncedSearchText = useDebounce(searchText, 600);

  useEffect(() => {
    const text = debouncedSearchText.trim().toLowerCase();

    const newData = dataSource.filter((item) =>
      Object.values(item)
        .map((value) => String(value).toLowerCase())
        .some((value) => value.includes(text))
    );

    setTableData(newData);
  }, [debouncedSearchText, dataSource]);

  useEffect(() => {
    const mappedData = dataSource.map((data, index) => ({
      'S.No': index + 1,
      id: index + 1,
      ...data,
      activeStatus: Number(data.activeStatus) ? 'Active' : 'In Active',
      enquiryStatus: Number(data.enquiryStatus) ? 'Active' : 'In Active',
      sibling: Number(data.sibling) ? 'Yes' : 'No'
    }));
    setData(mappedData);
  }, [dataSource]);

  useEffect(() => {
    // Check if all rows are selected
    const allSelected = tableData.length > 0 && selectedRows.length === tableData.length;
    const someSelected = selectedRows.length > 0 && selectedRows.length < tableData.length;

    const checkbox = document.getElementById('selectAllCheckbox');
    if (checkbox) {
      checkbox.checked = allSelected;
      checkbox.indeterminate = someSelected;
    }
  }, [selectedRows, tableData]);

  const handleRowClick = (row) => {
    const selectedIndex = selectedRows.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedRows, row.id];
    } else {
      newSelected = selectedRows.filter((id) => id !== row.id);
    }

    setSelectedRows(newSelected);
  };

  const handleSelectAllClick = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const newSelecteds = data.map((n) => n.id); // Use 'data' instead of 'tableData'
      setSelectedRows(newSelecteds);
    } else {
      setSelectedRows([]);
    }
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  const columnsWithSelection = [
    {
      field: 'selectAll',
      headerName: '',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <input
          id="selectAllCheckbox"
          type="checkbox"
          onChange={handleSelectAllClick}
        />
      ),
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.id)}
          onChange={() => handleRowClick(params.row)}
        />
      ),
    },
    ...column,
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Stack direction="row" spacing={3}>
          {handleViewClick && (
            <Tooltip placement="bottom" title={'View'}>
              <Button
                variant="outlined"
                onClick={() => handleViewClick(params.row)}
                style={{ backgroundColor: '#1f3bb3', width: '50px' }}
                size="sm"
              >
                <FaEye color="#fff" />
              </Button>
            </Tooltip>
          )}
          <Tooltip placement="bottom" title={'Edit'}>
            <Button
              variant="outlined"
              onClick={() => handleEditClick(params.row)}
              style={{ backgroundColor: '#ffaf00', width: '50px' }}
              size="sm"
            >
              <FaUserEdit color="#fff" />
            </Button>
          </Tooltip>
        </Stack>
      )
    }
  ].filter((e) => !e.hide);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: title.replace(/ +/g, ''),
            utf8WithBom: true
          }}
          printOptions={{
            hideToolbar: true
          }}
        />
      </GridToolbarContainer>
    );
  }

  const onClickAddHandler = () => {
    const selectedRowsData = data.filter((row) => selectedRows.includes(row.id));
    onClickAdd(selectedRowsData);
  };

  return (
    <Container style={{ width: '100%' }}>
      <Card style={{ height: '100%' }} bordered={true}>
        <div className="row align-items-center" style={{ paddingLeft: '11px', paddingTop: '4px' }}>
          <div className="col-lg-4 col-md-3 col-sm-12 mt-2 pb-4 row align-items-center">
            <OutlinedInput
              className="align-items-center ml-sm-2 mr-sm-2 shadow-sm"
              id="input-with-icon-adornment"
              placeholder="Search Here"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              size="small"
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              }
            />
          </div>
          <div className="col-lg-8 mt-2 col-md-9 col-sm-12 text-md-end text-lg-end text-center mt-sm-2">
            <div className="btn-group pb-2">
              <Tooltip placement="bottom" title={'Add'}>
                <Button
                  disabled={addButtonStatus}
                  onClick={onClickAddHandler}
                  className="btn btn-primary me-2 px-md-3 px-sm-4"
                >
                  Submit
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div style={{ height: 520, width: '100%' }}>
          <DataGrid
            density="compact"
            loading={loading}
            columns={columnsWithSelection}
            rows={isEmpty(searchText) ? data : tableData}
            hideFooterSelectedRowCount
            components={{
              Toolbar: CustomToolbar
            }}
            componentsProps={{
              pagination: {
                ActionsComponent: CustomPagination
              }
            }}
          />
        </div>
      </Card>
    </Container>
  );
};

export default CustomCheckboxTable;
