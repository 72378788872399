import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { columnCategory, columnName } from './column';
import UseFeesCategoryMaster from './UseFeesCategoryMaster';
import CustomDropdown from '../../../common/components/Dropdown/dropdown';
import { Tabs, Col, Button, Row, Form } from "antd";


const FeesCategory = ({ setTopTitle }) => {
  const { TabPane } = Tabs;

  useEffect(() => {
    setTopTitle('Fees Category & Name');
  }, [setTopTitle]);



  const {
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    feesCategoryValues,
    selectedValues,
    handleChange,
    DropdownValue,
    handleFormSubmit,
    feesNameValues
  } = UseFeesCategoryMaster();

  return (
    <>
      <div className='pad-lg'>
        <Col span={"24"}>
          <Tabs
            centered
            type='card'
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="Fees Category"
              key="1"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={feesCategoryValues}
                column={columnCategory}
                handleEditClick={handleEditClickStd}
                onClickAdd={onClickAddStd}
                title={"Standard Master List"}
              />
            </TabPane>
            <TabPane
              tab="Fees Name"
              key="2"
            >
              <Row gutter={[45, 0]} style={{justifyContent:'center'}}>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='dropdownId' label='Fees Category' >
                    <CustomDropdown
                      options={DropdownValue}
                      onChange={handleChange}
                      value={selectedValues} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>

                <Col md={{ span: 2 }} xs={{ span: 24 }} >
                  <Form.Item name='submit'>
                    <Button
                      onClick={handleFormSubmit}
                      style={{ background: '#34b1aa', color: "#ffffff" }}
                      className="btn btn-sm col-lg-1 col-m-2 col-sm-2 h-100 w-auto align-items-center">
                      {" "}
                      Filter
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <CustomTable
                dataSource={feesNameValues}
                column={columnName}
                handleEditClick={handleEditClickSec}
                onClickAdd={onClickAddSec}
                title={"Standard Master List"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>

    </>
  );
}

export default FeesCategory;
