/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import "./styles.css";
import { FaDatabase, FaUserAlt, FaEmpire } from "react-icons/fa";
import { RiLayoutGridFill } from "react-icons/ri";
import { Layout, Menu, } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../../logo.jpg";
import TopNavMenu from "./TopNavMenu";
import RoutesComponent from "./Routes";
import Footer from "./Footer";
import { useDispatch, } from "react-redux";
import { logOutReducer } from "../../@app/master/authSlice";

const { Sider } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const [TopTitle, setTopTitle] = useState("Dashboard");
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const module = userData?.data?.employee_mapping?.module ?? [];
  const screen = userData?.data?.employee_mapping?.module_Screen ?? [];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      const updateScreenSize = () => {
          setIsMobile(window.innerWidth < 768); // Adjust the width based on your mobile breakpoint
      };
      updateScreenSize();
      window.addEventListener('resize', updateScreenSize);
      return () => window.removeEventListener('resize', updateScreenSize);
  }, []);


  const handleLogout = () => {
    dispatch(logOutReducer());
    navigate('/login');
  }


  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        trigger={null}
        width={150}
        collapsible
        collapsed={collapsed}
        style={{ transition: "0.5s" }}
        className={`${collapsed ? "d-flex" : "d-none"} `}
      >
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "110%", padding: "35px" }}
            className="nav-logo"
          ></img>
        </div>
        <Menu
          mode="vertical"
          onClick={({ key }) => {
            if (key === "signout") {
              //TODO, sign out feature here
            } else {
              if (key !== "search") navigate(key);
            }
          }}
          style={{ backgroundColor: "black", color: "white" }}
        >
          {module.some(module => module.name === "Dashboard") && (
            <Menu.Item key="/dashboard" className="menu side-nav">
              <div className="flex flex-col">
                <div>
                  <RiLayoutGridFill size={28} color="#f5a60b" className="menu-icon" />
                </div>
                <div className="menu-title">Dashboard</div>
              </div>
            </Menu.Item>
          )}
          {module.some(module => module.name === "Master") && (
            <Menu.SubMenu
              className="side-nav maintext"
              key="sub1"
              title={
                <div>
                  <div>
                    <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  </div>
                  <div className="menu-title">Master</div>
                </div>
              }
            >
              {[
                {
                  key: "/employeeMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Employee Master",
                },
                {
                  key: "/academicMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Academic Master",
                },

              ].map(({ key, icon, title }) => (
                screen.some(screenName => screenName.name === title) && (
                  <Menu.Item key={key} icon={icon}>
                    <span>{title}</span>
                  </Menu.Item>
                )
              ))}
            </Menu.SubMenu>
          )}

          {module.some(module => module.name === "Sub Master") && (
            <Menu.SubMenu
              className="side-nav maintext"
              key="sub2"
              title={
                <div>
                  <div>
                    <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  </div>
                  <div className="menu-title">Sub Master</div>
                </div>
              }
            >
              {[
                {
                  key: "/dropdownMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Dropdown Master",
                },
                {
                  key: "/roleMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Role Master",
                },
                {
                  key: "/standardMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Standard Master",
                },
                {
                  key: "/subjectMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Subject Master",
                },
                {
                  key: "/termMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Term Master",
                },
                {
                  key: "/locationMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Location Master",
                },
                {
                  key: "/menuMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Menu Master",
                },
              ].map(({ key, icon, title }) => (
                screen.some(screenName => screenName.name === title) && (
                  <Menu.Item key={key} icon={icon}>
                    <span>{title}</span>
                  </Menu.Item>
                )
              ))}
            </Menu.SubMenu>
          )}
          {module.some(module => module.name === "Student Master") && (
            <Menu.SubMenu
              className="side-nav maintext"
              key="sub3"
              title={
                <div>
                  <div>
                    <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  </div>
                  <div className="menu-title">Students</div>
                </div>
              }
            >
              {[

                {
                  key: "/admissionMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Admission",
                },
                {key: "/enquiryMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Enquiry",
                },
                {key: "/timeTable",
                  icon: <FaUserAlt size={17} />,
                  title: "Time Table",
                },
                {key: "/classAllotment",
                  icon: <FaUserAlt size={17} />,
                  title: "Class Allotment",
                },
                {key: "/classTransfer",
                  icon: <FaUserAlt size={17} />,
                  title: "Class Transfer",
                },  

              ].map(({ key, icon, title }) => (
                screen.some(screenName => screenName.name === title) && (
                  <Menu.Item key={key} icon={icon}>
                    <span>{title}</span>
                  </Menu.Item>
                )
              ))}
            </Menu.SubMenu>
          )}
           {module.some(module => module.name === "Staff Master") && (
            <Menu.SubMenu
              className="side-nav maintext"
              key="sub4"
              title={
                <div>
                  <div>
                    <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  </div>
                  <div className="menu-title">Staffs</div>
                </div>
              }
            >
              {[

                {
                  key: "/staffMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Staff Master",
                },
              ].map(({ key, icon, title }) => (
                screen.some(screenName => screenName.name === title) && (
                  <Menu.Item key={key} icon={icon}>
                    <span>{title}</span>
                  </Menu.Item>
                )
              ))}
            </Menu.SubMenu>
          )}
          {module.some(module => module.name === "Fees Management") && (
            <Menu.SubMenu
              className="side-nav maintext"
              key="sub5"
              title={
                <div>
                  <div>
                    <FaDatabase size={10} color="#f5a60b" className="menu-icon" />
                  </div>
                  <div className="menu-title">Fees</div>
                </div>
              }
            >
              {[
                {
                  key: "/feesType",
                  icon: <FaUserAlt size={10} />,
                  title: "Fees Type",
                },
                {
                  key: "/feesCategory",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Category",
                },
                {
                  key: "/feesAllotment",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Allotment",
                },
                {
                  key: "/feesCollection",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Collection",
                },
                {
                  key: "/feesWaiveoff",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Waiveoff",
                },

              ].map(({ key, icon, title }) => (
                screen.some(screenName => screenName.name === title) && (
                  <Menu.Item key={key} icon={icon}>
                    <span>{title}</span>
                  </Menu.Item>
                )
              ))}
            </Menu.SubMenu>
          )}
           {module.some(module => module.name === "Reports") && (
            <Menu.SubMenu
              className="side-nav maintext"
              key="sub6"
              title={
                <div>
                  <div>
                    <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  </div>
                  <div className="menu-title">Reports</div>
                </div>
              }
            >
              {[

                {
                  key: "/studentHistoryReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Student History Report",
                },
                {
                  key: "/enquiryReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Enquiry Report",
                },
                {
                  key: "/admissionReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Admission Report",
                },
                {
                  key: "/dropReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Drop Report",
                },
                {
                  key: "/enqAdmCount",
                  icon: <FaUserAlt size={17} />,
                  title: "Enq & Adm Count",
                },
                {
                  key: "/pendingFeesReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Pending Fees Report",
                },
                {
                  key: "/feesCollectionReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Collection Report",
                },
                {
                  key: "/busStudentsReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Bus Students Report",
                },
                {
                  key: "/waiveoffReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Waive-off Report",
                },
                {
                  key: "/feesConcessionReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Concession Report",
                },
                {
                  key: "/studentFeesStructure",
                  icon: <FaUserAlt size={17} />,
                  title: "Student Fees Sructure",
                },
                {
                  key: "/studentAttendanceReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Student Attendance Report",
                },
                {
                  key: "/teacherAttendanceReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Teacher Attendance Report",
                },
                {
                  key: "/expenseReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Expense Report",
                },
                {
                  key: "/studentDetails",
                  icon: <FaUserAlt size={17} />,
                  title: "Student Details",
                },
                {
                  key: "/followupReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Follow-up Report",
                },
                {
                  key: "/menuAccessReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Menu Access Report",
                },

              ].map(({ key, icon, title }) => (
                screen.some(screenName => screenName.name === title) && (
                  <Menu.Item key={key} icon={icon}>
                    <span>{title}</span>
                  </Menu.Item>
                )
              ))}
            </Menu.SubMenu>
          )}

          {isMobile && (
            <Menu.Item key="/logout" className="menu side-nav" onClick={handleLogout}>
              <div className="flex flex-col">
                <div>
                  <RiLayoutGridFill size={28} color="#f5a60b" className="menu-icon" />
                </div>
                <div className="menu-title">Logout</div>
              </div>
            </Menu.Item>
          )}

        </Menu>
      </Sider>
      <Layout style={{ height: "100vh" }}>
        <TopNavMenu {...{ collapsed, setCollapsed, TopTitle }} />
        <Content setTopTitle={setTopTitle} />
        <Footer />
      </Layout>
    </Layout>
  );
}

function Content({ setTopTitle }) {
  const navigate = useNavigate();
  useEffect(() => {
    const loginStatus = localStorage.getItem("loginStatus") === "true";
    if (!loginStatus) {
      navigate(`/login`);
    }
  });

  return (
    <div style={{ height: "100vh", backgroundColor: "#F4F5F7", overflow: "auto" }}>
      <RoutesComponent setTopTitle={setTopTitle} />
    </div>
  );
}

export default App;
