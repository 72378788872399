export const columnRole = [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80},
      {key: '2', headerName: 'Role Name', field: 'roleName', hide: false, width: 250},
      {key: '3', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 200},
  ];
  
  export const columnDesignation = [
    {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 100},
    {key: '2', headerName: 'Designation Name', field: 'designationName', hide: false, width: 250},
    {key: '3', headerName: 'Designation Code', field: 'designationCode', hide: false, width: 250},
    {key: '4', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120},
];
