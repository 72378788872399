import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { column } from './column';
import UseTermMaster from './UseTermMaster';

const TermMaster = ({ setTopTitle }) => {
  
  useEffect(() => {
    setTopTitle('Term Master');
  }, [setTopTitle]);

  const {
    onClickAdd,
    handleEditClick,
    termMasterValues
  } = UseTermMaster();

  return (
    <div className="h-screen pad-lg">
      <CustomTable
        dataSource={termMasterValues}
        column={column}
        handleEditClick={handleEditClick}
        onClickAdd={onClickAdd}
        title={"Term Master List"}
      />
    </div>
  );
}

export default TermMaster;
