import React from 'react';
import { Card, Button, Radio, Col, Row, Form, Upload, Modal, Image } from 'antd';
import ConfirmOnExit from '../../../common/components/confirmOnExit/ConfirmOnExit';
import UseAdmissionMasterForm from './UseAdmissionMasterForm';
import useStudentImageAdd from './UseStudentImageAdd';
import CustomDropdown from '../../../common/components/Dropdown/dropdown';
import useGetDropdownListOptions from '../../../common/hooks/useGetDropdownListMaster';
import useGetStandardOptions from '../../../common/hooks/useGetStandardMaster';
import useGetAcademicOptions from '../../../common/hooks/useGetAcademicMaster';
import CustomDatePicker from '../../../common/components/DateInput/dateInput';
import CustomInput from '../../../common/components/TextInput/textInput';
import RadioYesButton from '../../../common/components/RadioButton/radioButton';



function AdmissionMasterForm({ setTopTitle }) {
  setTopTitle('Admission Form');
  const { gender, salaryRange, enquiryBy, admissionType } = useGetDropdownListOptions();
  const { getStandardOptionData } = useGetStandardOptions();
  const { getAcademicYear } = useGetAcademicOptions();

  const {
    getFile, previewImage, previewOpen, previewTitle, fileList, handleChange, handleCancel,
    handlePreview, uploadButton, props, image } = useStudentImageAdd();
  const {
    onFinish, showDialog, handleClickBack, isEdit, selectedSalary, selectedAdmissionBy,
    defaultValue, selectedStandardOption, selectedAcademicYear, selectedGender, selectedAdmissionOption,
    form, handleSalary, handleEnyBy, handleAdmType, handleGender, handleEnyStd, handleAcdYear,
    handleSibling, disabledFutureDates } = UseAdmissionMasterForm(image);

  return (
    <>
      <Card>
        <ConfirmOnExit showModel={showDialog} />
        <Row>
          <Col span={24}>
            <Form
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{
                studentName: defaultValue?.stName,
                gender: defaultValue?.gender,
                stDob: defaultValue?.stDob,
                doa: defaultValue?.doa,
                parentType: defaultValue?.parentType,
                admissionStd: defaultValue?.admissionStd,
                academicYear: defaultValue?.academicYear,
                fatherName: defaultValue?.fatherName,
                motherName: defaultValue?.motherName,
                guardianName: defaultValue?.guardianName,
                admissionType: defaultValue?.admissionType,
                fatherNumber: defaultValue?.fatherNumber,
                motherNumber: defaultValue?.motherNumber,
                guardianNumber: defaultValue?.guardianNumber,
                fatherOccupation: defaultValue?.fatherOccupation,
                motherOccupation: defaultValue?.motherOccupation,
                guardianOccupation: defaultValue?.guardianOccupation,
                salary: defaultValue?.salary,
                religion: defaultValue?.religion,
                caste: defaultValue?.caste,
                previousSchool: defaultValue?.previousSchool,
                studentImage: defaultValue?.studentImage,
                sibling: defaultValue?.sibling === 'No' ? 'inActive' : 'active',
                activeStatus: defaultValue?.activeStatus === 'In Active' ? 'inActive' : 'active',
              }}
              onFinish={onFinish}
              autoComplete='off'
              form={form}>
              <Row gutter={[15, 0]}>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Student Name' name='studentName'
                    rules={[{ required: true, message: 'Please Enter The Student Name!' }]}>
                    <CustomInput placeholder='Student Name' name='studentName' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='gender' label='Gender' >
                    <CustomDropdown
                      options={gender}
                      onChange={handleGender}
                      value={selectedGender}
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item name="stDob" label="Date Of Birth "
                    labelCol={{ md: { span: 24 }, xs: { span: 24 }, style: { textAlign: 'left' } }}>
                    <CustomDatePicker
                      disabledDate={disabledFutureDates} // 
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='parentType' label='Parent / Guardian' >
                    <CustomDropdown
                      options={enquiryBy}
                      onChange={handleEnyBy}
                      value={selectedAdmissionBy} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='admissionStd' label='Admission Standard' >
                    <CustomDropdown
                      options={getStandardOptionData}
                      onChange={handleEnyStd}
                      value={selectedStandardOption} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='admissionType' label='Admission Type' >
                    <CustomDropdown
                      options={admissionType}
                      onChange={handleAdmType}
                      value={selectedAdmissionOption} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='academicYear' label='Academic Year' >
                    <CustomDropdown
                      options={getAcademicYear}
                      onChange={handleAcdYear}
                      value={selectedAcademicYear} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                {selectedAdmissionBy === 46 && (
                  <>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Father Name' name='fatherName'
                        rules={[{ required: true, message: 'Please input the subject code!' }]}>
                        <CustomInput placeholder='Father Name' name='fatherName' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Mother Name' name='motherName'
                        rules={[{ required: true, message: 'Please input the subject code!' }]}>
                        <CustomInput placeholder='Father Name' name='motherName' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Father Number' name='fatherNumber'
                        rules={[{ required: true, message: 'Please input the subject code!' }]}>
                        <CustomInput placeholder='Father Number' name='fatherNumber' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Mother Number' name='motherNumber'
                        rules={[{ required: true, message: 'Please input the subject code!' }]}>
                        <CustomInput placeholder='Mother Number' name='motherNumber' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Father Occupation' name='fatherOccupation'
                        rules={[{ required: true, message: 'Please input the subject code!' }]}>
                        <CustomInput placeholder='Father Occupation' name='fatherOccupation' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Mother Occupation' name='motherOccupation'
                        rules={[{ required: true, message: 'Please input the subject code!' }]}>
                        <CustomInput placeholder='Mother Occupation' name='motherOccupation' />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {selectedAdmissionBy === 47 && (
                  <>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Guardian Name' name='guardianName'
                        rules={[{ required: true, message: 'Please input the subject code!' }]}>
                        <CustomInput placeholder='Guardian Name' name='guardianName' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Guardian Number' name='guardianNumber'
                        rules={[{ required: true, message: 'Please input the subject code!' }]}>
                        <CustomInput placeholder='Guardian Number' name='guardianNumber' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Guardian Occupation' name='guardianOccupation'
                        rules={[{ required: true, message: 'Please input the subject code!' }]}>
                        <CustomInput placeholder='Guardian Occupation' name='guardianOccupation' />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Address' name='address'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomInput placeholder='Address' name='address' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='salary' label='Salary'  >
                    <CustomDropdown
                      options={salaryRange}
                      onChange={handleSalary}
                      value={selectedSalary} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item name="doa" label="Admission Date "
                    labelCol={{ md: { span: 24 }, xs: { span: 24 }, style: { textAlign: 'left' } }}>
                    <CustomDatePicker
                      disabledDate={disabledFutureDates} // Pass the disabled date function here
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item name='sibling' label='Sibling'
                    rules={[{ required: true, message: 'Please select your status' }]}>
                    <RadioYesButton onChange={handleSibling}
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Refer By' name='referBy'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomInput placeholder='Refer By' name='referBy' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Religion' name='religion'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomInput placeholder='Religion' name='religion' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Caste' name='caste'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomInput placeholder='Caste' name='caste' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Previous School' name='previousSchool'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <CustomInput placeholder='Previous School' name='previousSchool' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item
                    name='studentImage'
                    label='Student Image'
                    getValueFromEvent={getFile}
                  >
                    <div style={{ display: 'flex', direction: 'col' }}>
                      {defaultValue?.id && fileList?.length === 0 ? (
                        defaultValue?.image ? (
                          <Image style={{ paddingRight: '10px' }} width={100} src={defaultValue?.image ?? ''} />
                        ) : (
                          'No Image Available'
                        )
                      ) : (
                        <></>
                      )}
                      <Upload
                        {...props}
                        fileList={fileList}
                        onPreview={handlePreview}
                        capture='environment'
                        accept='.png,.jpg,.jpeg'
                        onChange={(e) => {
                          handleChange(e);
                        }}>
                        {fileList.length >= 1 ? null : uploadButton}
                      </Upload>
                    </div>
                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                        alt='example'
                        style={{
                          width: '100%'
                        }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item name='activeStatus' label='Status'
                    rules={[{ required: true, message: 'Please select your status' }]}>
                    <Radio.Group buttonStyle='solid' size='middle' name='activeStatus' >
                      <Radio.Button className='active' value='active'>
                        Active
                      </Radio.Button>
                      <Radio.Button className='in-active' value='inActive'>
                        In-Active
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[15, 15]} style={{ justifyContent: 'end' }}>
                    <Col span={12} style={{ textAlign: 'right' }} className='d-flex align-items-center justify-content-end mt-3'>
                      <Form.Item className='mx-2'>
                        <Button className='orangeFactory' type='primary' htmlType='submit'>
                          {isEdit ? 'Update' : 'Add'}
                        </Button>
                      </Form.Item>
                      {/* </Col>
                    <Col span={12}> */}
                      <Form.Item>
                        <Button onClick={handleClickBack}>Back</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default AdmissionMasterForm;
