import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../api/constants/index';
import { clearData, fetchData } from '../../@app/slices/fetch-slice';
import { getData, getStatus } from '../../@app/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetStandardOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getStandardOption = getData(state, apiKeys.standardOptions, []) || [];
  const getStandardStatus = getStatus(state, apiKeys.standardOptions, '');
  const getStandardLoading = apiStatuses.pending === getStandardStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.standardOptions,
        method: apiMethods.GET,
        key: apiKeys.standardOptions
      })
    );
  }, [dispatch]);

  const getStandardOptionData= useMemo(() => {
    return getStandardOption.filter((option) => option.activeStatus === "1")
    .map(({ stdId, standard }) => ({
      value: stdId,
      label: standard
    }))
  }, [getStandardOption]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.standardOptions,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getStandardOption,
      getStandardOptionData,
      getStandardStatus,
      getStandardLoading
    }),
    [getStandardOption, getStandardOptionData, getStandardStatus, getStandardLoading]
  );
}

export default useGetStandardOptions;
