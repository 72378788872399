import React from 'react';
import {Input, Card, Button, Radio, Col, Row, Form} from 'antd';
import ConfirmOnExit from '../../../common/components/confirmOnExit/ConfirmOnExit';
import useAcademicMasterForm from './UseAcademicMasterForm';


function AcademicMasterForm({ setTopTitle }) {
    setTopTitle('Academic Master');

    const {
      onFinish, showDialog, handleClickBack, isEdit, defaultValue
    } = useAcademicMasterForm();


  const [form] = Form.useForm();

  return (
    <>
      <Card>
        <ConfirmOnExit showModel={showDialog} />
        <Row>
          <Col span={24}>
            <Form
              name='basic'
              labelCol={{span: 24}}
              wrapperCol={{span: 24}}
              initialValues={{
                academicCode: defaultValue?.academicCode,
                academicName: defaultValue?.academicName,
                academicYear: defaultValue?.academicYear,
                startYear: defaultValue?.startYear,
                endYear: defaultValue?.endYear,
                status: defaultValue?.status === 'In Active' ? 'inActive' : 'active'
              }}
              onFinish={onFinish}
              autoComplete='off'
              form={form}>
              <Row gutter={[15, 0]}>
                <Col md={{span: 6}} xs={{span: 24}}>
                  <Form.Item label='Academic Code' name='academicCode' 
                  rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <Input placeholder='Academic Code' name='academicCode'  />
                  </Form.Item>
                </Col>
                <Col md={{span: 6}} xs={{span: 24}}>
                  <Form.Item label='Academic Name' name='academicName'
                  rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <Input placeholder='Academic Name' name='academicName'  />
                  </Form.Item>
                </Col>
                <Col md={{span: 6}} xs={{span: 24}}>
                  <Form.Item label='Academic Year' name='academicYear'
                  rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <Input placeholder='Academic Year' name='academicYear'  />
                  </Form.Item>
                </Col>
                <Col md={{span: 6}} xs={{span: 24}}>
                  <Form.Item label='Start Year' name='startYear'
                  rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <Input placeholder='Start Year' name='startYear'  />
                  </Form.Item>
                </Col>
                <Col md={{span: 6}} xs={{span: 24}}>
                  <Form.Item label='End Year' name='endYear'
                  rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <Input placeholder='End Year' name='endYear'  />
                  </Form.Item>
                </Col>
                <Col md={{span: 6}} xs={{span: 24}}>
                  <Form.Item name='status' label='Status'
                   rules={[{required: true, message: 'Please select your status'}]}>
                    <Radio.Group buttonStyle='solid' size='middle' name='status' >
                      <Radio.Button className='active' value='active'>
                        Active
                      </Radio.Button>
                      <Radio.Button className='in-active' value='inActive'>
                        In-Active
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[15, 15]} style={{justifyContent: 'end'}}>
                    <Col span={12} style={{textAlign: 'right'}} className='d-flex align-items-center justify-content-end mt-3'>
                      <Form.Item className='mx-2'>
                        <Button className='orangeFactory' type='primary' htmlType='submit'>
                          {isEdit ? 'Update' : 'Add'}
                        </Button>
                      </Form.Item>
                      {/* </Col>
                    <Col span={12}> */}
                      <Form.Item>
                        <Button onClick={handleClickBack}>Back</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default AcademicMasterForm;
