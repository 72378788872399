// const apiServicePath = '/';
import { baseURL } from "../baseURL";

const mockApiBaseUrl = '/mock/'
export const urls = Object.freeze({

  signIn: `${baseURL}web-login`,
  authenticate: `${baseURL}authenticate`,
  
  employeeMaster: `${mockApiBaseUrl}employeeMaster`,
  saveEmployeeMaster: `${mockApiBaseUrl}saveEmployeeMaster`,
  deleteEmployeeMaster: (id) => `${mockApiBaseUrl}deleteEmployeeMaster/${id}`,
  deleteSelectedEmployees: `${mockApiBaseUrl}employee-master/delete`,

  primaryCustomer: `${baseURL}foods-primarycustomer-list`,
  getPrimeCustDataFromSap: `${baseURL}foods-primarycustomer-list`,
  primeCustListBySubzone: `${baseURL}primarycustomer-by-subzone`,
  savePrimaryCustomerMaster: `${baseURL}foods-primarycustomer-store`,
  updatePrimaryCustomerMaster: `${baseURL}foods-primarycustomer-update`,
  deletePrimaryCustomerMaster: (id) => `${baseURL}foods-primarycustomer-destroy/${id}`,
  getEditPrimaryCustomerMaster : (id) => `${baseURL}foods-primarycustomer-show/${id}`,

  outletMaster: `${baseURL}get-outletMaster`,

  //student
  addAdmissionMasterData: `${baseURL}add-admissionMasterData`,
  admissionMasterData: `${baseURL}get-admissionMasterData`,
  classAllotmentData: `${baseURL}get-classAllotmentData`,
  addEnquiryMasterData: `${baseURL}add-enquiryData`,
  updateEnquiryMasterData: `${baseURL}update-enquiryData`,
  enquiryMasterData: `${baseURL}get-enquiryMasterData`,
  timeTableMasterData: `${baseURL}get-timeTableMasterData`,

  //fees management
  feesTypeData: `${baseURL}get-feesTypeData`,
  feesCategoryData: `${baseURL}get-feesCategoryData`,
  feesNameData: `${baseURL}get-feesNameData`,
  feesAllotmentData: `${baseURL}get-feesAllotmentData`,


  academicMasterData: `${baseURL}get-academicMasterData`,
  countryMasterData: `${baseURL}get-countryMasterData`,
  dropdownMasterData: `${baseURL}get-dropdownMasterData`,
  getMenuMasterData: `${baseURL}get-menuMasterData`,
  getMenuListData: `${baseURL}get-menuListData`,
  getMenuAccessData: `${baseURL}get-menuAccessData`,
  dropdownListMasterData: `${baseURL}get-dropdownListMasterData`,
  districtMasterData: `${baseURL}get-districtMasterData`,
  designationMasterData: `${baseURL}get-designationMasterData`,
  roleMasterData: `${baseURL}get-roleMasterData`,
  standardMasterData: `${baseURL}get-standardMasterData`,
  standardOptions: `${baseURL}get-standardOptions`,
  sectionMasterData: `${baseURL}get-sectionMasterData`,
  subjectMasterData: `${baseURL}get-subjectMasterData`,
  stateMasterData: `${baseURL}get-stateMasterData`,
  termMasterData: `${baseURL}get-termMasterData`,








  
  
});

