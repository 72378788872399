import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../api/constants/index';
import { clearMultipleData, fetchData } from '../../@app/slices/fetch-slice';
import { getData, getStatus } from '../../@app/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDropdownListById() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getDropdownListData = getData(state, apiKeys.dropdownListMasterData, []) || []; // Ensure getDropdownListData is an arrayconst getDropdownListData = getData(state, apiKeys.dropdownListMasterData, []);
  const getDropdownListStatus = getStatus(state, apiKeys.dropdownListMasterData, '');
  const getDropdownListLoading = apiStatuses.pending === getDropdownListStatus;


  const getDropdownData = useMemo(() => {
    return (dropdownId) => {
      dispatch(fetchData({
        url: apiUrls.dropdownListMasterData,
        method: apiMethods.POST, 
        key: apiKeys.dropdownListMasterData,
        body: { dropdownId: dropdownId }
      }));
    };
  }, [dispatch]);
  

  const DropdownValue = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.activeStatus === "1")
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

 
  

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.dropdownlistMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getDropdownListData,
      getDropdownListStatus,
      getDropdownListLoading,
      DropdownValue,
      getDropdownData
    }),
    [
      getDropdownListData,
      getDropdownListStatus,
      getDropdownListLoading,
      DropdownValue,
      getDropdownData
    ]
  );
}

export default useGetDropdownListById;
