// navs.js
const navs = {
  admissionMaster: "/admissionMaster",
  admissionMasterForm: "/admissionMaster/addForm",
  academicMaster: "/academicMaster",
  academicMasterForm: "/academicMaster/addForm",
  enquiryMaster: "/enquiryMaster",
  enquiryMasterForm: "/enquiryMaster/addForm",
  staffMaster: "/staffMaster",
  staffMasterForm: "/staffMaster/addForm",
  subjectMaster: "/subjectMaster",
  subjectMasterForm: "/subjectMaster/addForm",
  menuMaster: "/menuMaster",
  menuMasterForm: "/menuMaster/addForm",
  menuListMasterForm: "/menuListMaster/addForm",
  menuAccessMasterForm: "/menuAccessMaster/addForm",
};

module.exports = navs;
