import { useMemo, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from "../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';

export default function useDropdownMaster() {
  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState([]);

  const dropdownMasterValues = getData(state, apiKeys.dropdownMasterData, []);
  const dropdownListMasterValues = getData(state, apiKeys.dropdownListMasterData, []);

  const handleFormSubmit = () => {
    dispatch(fetchData({
      url: apiUrls.dropdownListMasterData,
      method: apiMethods.POST,
      key: apiKeys.dropdownListMasterData,
      body: { dropdownId: selectedValues }
    }));
  };

  const getDropdownData = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.dropdownMasterData,
      method: apiMethods.GET,
      key: apiKeys.dropdownMasterData,
    }));
  }, [dispatch]);

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    handleFormSubmit(); // Call handleFormSubmit whenever selectedValues change
  }, [selectedValues]);

  const handleChange = (selectedValues) => {
    setSelectedValues(selectedValues);
  };

  const onClickAddStd = () => {
    navigate('/outletMaster/addForm');
  };

  const handleEditClickStd = (data) => {
    navigate('/outletMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  const onClickAddSec = () => {
    navigate('/outletMaster/addForm');
  };

  const handleEditClickSec = (data) => {
    navigate('/outletMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  return useMemo(() => ({
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    selectedValues,
    handleEditClickSec,
    dropdownMasterValues,
    dropdownListMasterValues,
    handleChange,
    handleFormSubmit
  }), [
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    selectedValues,
    handleEditClickSec,
    dropdownMasterValues,
    dropdownListMasterValues,
    handleFormSubmit
  ]);
}
