import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { columnDropdown, columnDropdownList } from './column';
import UseStandardMaster from './UseDropdownMaster';
import { Tabs, Col, Button, Row, Form } from "antd";
import CustomDropdown from '../../../common/components/Dropdown/dropdown';
import useGetDropdownOptions from '../../../common/hooks/useGetDropdownMaster';

const StandardMaster = ({ setTopTitle }) => {
  const { TabPane } = Tabs;

  useEffect(() => {
    setTopTitle('Dropdown & List Master');
  }, [setTopTitle]);

  const {getDropdownOptions} = useGetDropdownOptions();

  const {
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    selectedValues,
    handleChange,
    handleFormSubmit,
    handleEditClickSec,
    dropdownMasterValues,
    dropdownListMasterValues
  } = UseStandardMaster();

  
  // const handleFormSubmit = () => {
  //   dispatch(fetchData({
  //     url: apiUrls.dropdownListMasterData,
  //     method: apiMethods.POST,
  //     key: apiKeys.dropdownListMasterData,
  //     body:{dropdownId:selectedValues}
  //   }));
  // };

 

  return (
    <>
      <div className='pad-lg'>
        <Col span={"24"}>
          <Tabs
            centered
            type='card'
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="Dropdown Master"
              key="1"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={dropdownMasterValues}
                column={columnDropdown}
                handleEditClick={handleEditClickStd}
                onClickAdd={onClickAddStd}
                title={"Dropdown Master"}
              />
            </TabPane>
            <TabPane
              tab="Dropdown List Master"
              key="2"
            >
              <Row gutter={[45, 0]}>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='dropdownId' label='Dropdown Name' >
                    <CustomDropdown
                      options={getDropdownOptions}
                      onChange={handleChange}
                      value={selectedValues} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="multiple"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>

                <Col md={{ span: 2 }} xs={{ span: 24 }} >
                  <Form.Item name='submit'>
                    <Button
                      onClick={handleFormSubmit}
                      style={{ background: '#34b1aa', color: "#ffffff" }}
                      className="btn btn-sm col-lg-1 col-m-2 col-sm-2 h-100 w-auto align-items-center">
                      {" "}
                      Filter
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <CustomTable
                dataSource={dropdownListMasterValues}
                column={columnDropdownList}
                handleEditClick={handleEditClickSec}
                onClickAdd={onClickAddSec}
                title={"Dropdown List Master"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>

    </>
  );
}

export default StandardMaster;
