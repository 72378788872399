import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { columnMenuMaster, columnMenuList, columnMenuAccess } from './column';
import UseMenuMaster from './UseMenuMaster';
import { Tabs, Col,  } from "antd";

const MenuMaster = ({ setTopTitle }) => {
  const { TabPane } = Tabs;

  useEffect(() => {
    setTopTitle('Menu Master');
  }, [setTopTitle]);

  const {
    onClickAddMenu,
    handleEditClickMenu,
    onClickAddList,
    onClickAddAccess,
    handleEditClickList,
    handleEditClickAccess,
    menuValues,
    screenValues,
    accessValues
  } = UseMenuMaster();

  return (
    <>
      <div className='pad-lg'>
        <Col span={"24"}>
          <Tabs
            centered
            type='card'
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="Menu Master"
              key="1"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={menuValues}
                column={columnMenuMaster}
                handleEditClick={handleEditClickMenu}
                onClickAdd={onClickAddMenu}
                title={"Menu Master List"}
              />
            </TabPane>
            <TabPane
              tab="Menu List"
              key="2"
            >
              <CustomTable
                dataSource={screenValues}
                column={columnMenuList}
                handleEditClick={handleEditClickList}
                onClickAdd={onClickAddList}
                title={"Modules Master List"}
              />
            </TabPane>
            <TabPane
              tab="Menu Access"
              key="3"
            >
              <CustomTable
                dataSource={accessValues}
                column={columnMenuAccess}
                handleEditClick={handleEditClickAccess}
                onClickAdd={onClickAddAccess}
                title={"Access Master List"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>

    </>
  );
}

export default MenuMaster;
