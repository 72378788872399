import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from "../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';

export default function useSubjectMaster() {
  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const feesTypeValues = getData(state, apiKeys.feesTypeData, []);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.feesTypeData,
      method: apiMethods.GET,
      key: apiKeys.feesTypeData,
    }));
  }, [dispatch]);

  const onClickAdd = () => {
    navigate('/subjectMaster/addForm');
  };

  const handleEditClick = (data) => {
    navigate('/subjectMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  

  return useMemo(() => ({
    onClickAdd,
    handleEditClick,
    feesTypeValues
  }), [onClickAdd, handleEditClick, feesTypeValues]);
}
