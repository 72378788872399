import React, {  } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { column } from './column';
import UseAdmissionMaster from './UseAdmissionMaster';


const AdmissionMaster = ({ setTopTitle }) => {
  
    setTopTitle('Admission Master');

  const {
    onClickAdd,
    handleEditClick,
    admissionMasterValues
  } = UseAdmissionMaster();

  return (
    <div className="h-screen">
    
      <CustomTable
        dataSource={admissionMasterValues}
        column={column}
        handleEditClick={handleEditClick}
        onClickAdd={onClickAdd}
        title={"Admission Master List"}
      />
    </div>
  );
}

export default AdmissionMaster;
