import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from "../../../@app/slices/helpers/helpers";
import { useMemo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

function useMenuMasterForm() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const isEdit = locationState?.isEdit || false;
  let defaultValue = locationState?.data;

  const mainMenuData = getData(state, apiKeys.getMenuMasterData, []);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.getMenuMasterData,
      method: apiMethods.GET,
      key: apiKeys.getMenuMasterData,
    }))
  }, [dispatch]);

  const getMenuName = useMemo(() => {
    return mainMenuData.filter((option) => option.activeStatus === "1")
    .map(({ id, name }) => ({
      value: id,
      label: name
    }))
  }, [mainMenuData]);

  const onFinish = (values) => {
    setShowDialog(false);
    let data = {
      moduleId: values.moduleId,
      name: values.name,
      status: values.status === 'active' ? 1 : 0,
    };
 
    dispatch(fetchData({
      url: apiUrls.dropdownListMasterData,
      method: apiMethods.POST,
      key: apiKeys.dropdownListMasterData,
      body: { data }
    }));
  }

  const handleClickBack = () => {
    navigate('/menuMaster');
  };

  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    mainMenuData,
    defaultValue,
    getMenuName,
    isEdit
  }), [onFinish, showDialog, handleClickBack,
    mainMenuData, defaultValue, getMenuName, isEdit]);
}

export default useMenuMasterForm;
