export const column = [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 100},
      {key: '2', headerName: 'Fees Type', field: 'dropdownListName', hide: false, width: 180},
      {key: '3', headerName: 'Concession',
         field: 'addColOne', 
         renderCell: (params) => {
            return params.row.addColOne + '%';
          },
         hide: false, width: 180},
      {key: '4', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 180},
  ];
  