import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';
import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Form, } from 'antd';
// import { getStatus } from '../../../@app/slices/helpers/helpers';

export default function useAdmissionMasterForm(image) {

  const dispatch = useDispatch();
  const { state: locationState } = useLocation(); // Rename state to locationState
  // const state = useSelector((store) => store); // Renamed to reduxState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedSalary, setSelectedSalary] = useState([]);
  const [selectedStandardOption, setSelectedStandardOption] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState([]);
  const [selectedAdmissionBy, setSelectedAdmissionBy] = useState([]);
  const [selectedSibling, setSelectedSibling] = useState([]);
  const [selectedAdmissionOption, setSelectedAdmissionOption] = useState([]);

  // const addAdmissionMasterStatus = getStatus(state, apiKeys.addAdmissionMasterData, '');

  const isEdit = locationState?.isEdit || false; // Updated to use locationState
  let defaultValue = locationState?.data; // Updated to use locationState

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setShowDialog(false);

    dispatch(fetchData({
      url: apiUrls.addAdmissionMasterData,
      method: apiMethods.POST,
      key: apiKeys.addAdmissionMasterData,
      body: {...values, image} 
    }))
    .then((response) => {
      if (response?.payload?.status) {
        navigate('/admissionMaster');
      } else {
        console.log('asdasd');
      }
    })

  }

  const handleGender = (selectedGender) => {
    setSelectedGender(selectedGender);
  };
  const handleEnyFor = (selectedSalary) => {
    setSelectedSalary(selectedSalary);
  };
  const handleEnyBy = (selectedAdmissionBy) => {
    setSelectedAdmissionBy(selectedAdmissionBy);
  };
  const handleEnyStd = (selectedStandardOption) => {
    setSelectedStandardOption(selectedStandardOption);
  };
  const handleAcdYear = (selectedAcademicYear) => {
    setSelectedAcademicYear(selectedAcademicYear);
  };
  const handleAdmType = (selectedAdmissionOption) => {
    setSelectedAdmissionOption(selectedAdmissionOption);
  };
  const disabledFutureDates = (current) => {
    const today = new Date();
    return current && current > today;
  };


  const handleSibling = (selectedSibling) => {
    setSelectedSibling(selectedSibling);
  };

  

  const handleClickBack = () => {
    navigate('/admissionMaster');
  };


  // useEffect(() => {
  //   if (addAdmissionMasterStatus === apiStatuses.succeeded) {
  //     console.log('865');
  //   }
  // }, [addAdmissionMasterStatus])

  // useEffect(
  //   () => () => {
  //     dispatch(clearMultipleData({ key: [apiKeys.addAdmissionMasterData,] }));
  //   },
  //   [dispatch]
  // );

  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    handleGender,
    handleEnyFor,
    handleEnyBy,
    handleEnyStd,
    handleAcdYear,
    handleSibling,
    handleAdmType,
    defaultValue,
    selectedGender, selectedSalary, selectedSibling,
    selectedStandardOption, selectedAcademicYear, selectedAdmissionBy,
    disabledFutureDates, selectedAdmissionOption,
    isEdit,
    form
  }), [onFinish,
    showDialog,
    handleClickBack,
    handleGender,
    handleEnyFor,
    handleEnyBy,
    handleEnyStd,
    handleAcdYear,
    handleSibling,
    handleAdmType,
    defaultValue,
    selectedGender, selectedSalary, selectedSibling,
    selectedStandardOption, selectedAcademicYear, selectedAdmissionBy,
    disabledFutureDates, selectedAdmissionOption,
    isEdit, form]);
}