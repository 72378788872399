import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { columnStandard, columnSection } from './column';
import UseStandardMaster from './UseStandardMaster';
import { Tabs, Col,  } from "antd";

const StandardMaster = ({ setTopTitle }) => {
  const { TabPane } = Tabs;

  useEffect(() => {
    setTopTitle('Standard & Section Master');
  }, [setTopTitle]);

  const {
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    standardMasterValues,
    sectionMasterValues
  } = UseStandardMaster();

  return (
    <>
      <div className='pad-lg'>
        <Col span={"24"}>
          <Tabs
            centered
            type='card'
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="Standard"
              key="3"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={standardMasterValues}
                column={columnStandard}
                handleEditClick={handleEditClickStd}
                onClickAdd={onClickAddStd}
                title={"Standard Master List"}
              />
            </TabPane>
            <TabPane
              tab="Section"
              key="4"
            >
              <CustomTable
                dataSource={sectionMasterValues}
                column={columnSection}
                handleEditClick={handleEditClickSec}
                onClickAdd={onClickAddSec}
                title={"Standard Master List"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>

    </>
  );
}

export default StandardMaster;
