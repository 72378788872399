import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';
import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import {useLocation, useNavigate} from 'react-router';

export default function useMenuMasterForm() {
  const dispatch = useDispatch();
  // const {state} = useLocation();
  const { state: locationState } = useLocation(); // Rename state to locationState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const isEdit = locationState?.isEdit || false;
  let defaultValue = locationState?.data;

  const onFinish = (values) => {
    setShowDialog(false);
    let data = {
      menuCode: values.menuCode,
      menuName: values.menuName,
      status: values.status === 'active' ? 1 : 0,
    };

    dispatch(fetchData({
      url: apiUrls.dropdownListMasterData,
      method: apiMethods.POST,
      key: apiKeys.dropdownListMasterData,
      body: { data }
    }));

  }

  const handleClickBack = () => {
    navigate('/menuMaster');
  };


  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    defaultValue,
    isEdit
  }), [onFinish, showDialog, handleClickBack, defaultValue, isEdit]);
}