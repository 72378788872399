import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { columnDistrict, columnState, columnCountry } from './column';
import UseLocationMaster from './UseLocationMaster';
import { Tabs, Col,  } from "antd";

const DistrictMaster = ({ setTopTitle }) => {
  const { TabPane } = Tabs;

  useEffect(() => {
    setTopTitle('Location Master');
  }, [setTopTitle]);

  const {
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    districtMasterValues,
    stateMasterValues,
    countryMasterValues
  } = UseLocationMaster();

  return (
    <>
      <div className='pad-lg'>
        <Col span={"24"}>
          <Tabs
            centered
            type='card'
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="District"
              key="1"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={districtMasterValues}
                column={columnDistrict}
                handleEditClick={handleEditClickStd}
                onClickAdd={onClickAddStd}
                title={"District Master List"}
              />
            </TabPane>
            <TabPane
              tab="State"
              key="2"
            >
              <CustomTable
                dataSource={stateMasterValues}
                column={columnState}
                handleEditClick={handleEditClickSec}
                onClickAdd={onClickAddSec}
                title={"District Master List"}
              />
            </TabPane>
            <TabPane
              tab="Country"
              key="3"
            >
              <CustomTable
                dataSource={countryMasterValues}
                column={columnCountry}
                handleEditClick={handleEditClickSec}
                onClickAdd={onClickAddSec}
                title={"District Master List"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>

    </>
  );
}

export default DistrictMaster;
