import { apiStatuses } from '../../../api/constants/index';

export const getData = (state, key, defaultValue = null) =>
 (state.fetch[key] && state.fetch[key]?.data?.response?.data) || defaultValue;

export const getStatus = (state, key, defaultValue = null) =>
  (state.fetch[key] && state.fetch[key]?.status) || defaultValue;

export const getError = (state, key, defaultValue = null) =>
  (state.fetch[key] && state.fetch[key]?.data?.response?.error) || defaultValue;

export const getErrorMessage = (state, key, defaultValue = null) => {
  let result = defaultValue;
  // const error = getError(state, key, {});
  if (getStatus(state, key, '') === apiStatuses.rejected) {
    result = ( state.fetch[key] && state.fetch[key]?.data?.response?.message) 
      || defaultValue
      || "API is failed to respond";
  }
  return result;
};

export const getMessage = (state, key, defaultValue = null) =>
  (state.fetch[key] && state.fetch[key]?.data?.response?.message) || defaultValue;


export const getGlobalState = (state = {}, key = '', defaultValue = null) =>
  (state?.global && state?.global[key] !== undefined && state?.global[key]) || defaultValue;
