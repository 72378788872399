import { useMemo, useEffect,  } from 'react';
import { useNavigate } from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import { getData, } from "../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';
import navs from '../../../api/constants/navs'

export default function useTermMaster() {
    
  const state = useSelector((store) => store);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const academicMasterValues  = getData(state, apiKeys.academicMasterData, []);
 

    // console.log(divisionMasterStatus,'---')
    // console.log(divisionMasterErrMsg,'666')
    // console.log(deleteDivisionMasterStatus,'777')
  
    // const getTermMaster = useCallback(() => {
    //   dispatch(fetchData({
    //     url: apiUrls.termMasterData,
    //     method: apiMethods.GET,
    //     key: apiKeys.termMasterData,
    //   }))
    // }, [dispatch])
  
    useEffect(() => {
      dispatch(fetchData({
        url: apiUrls.academicMasterData,
        method: apiMethods.GET,
        key: apiKeys.academicMasterData,
      }))
    }, [dispatch, ]);

    const onClickAdd = () => {
        navigate(navs.academicMasterForm);
    };

    const handleEditClick = (data) => {
        navigate(navs.academicMasterForm, {
          state: {data, isEdit: true}
        });
      };

    return useMemo(() => ({
        onClickAdd,
        handleEditClick,
        academicMasterValues
    }), [
        onClickAdd,
        handleEditClick,
        academicMasterValues
 
    ]
    )

}