import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { column } from './column';
import UseAcademicMaster from './UseAcademicMaster';

const AcademicMaster = ({ setTopTitle }) => {
  
  useEffect(() => {
    setTopTitle('Academic Master');
  }, [setTopTitle]);

  const {
    onClickAdd,
    handleEditClick,
    academicMasterValues
  } = UseAcademicMaster();

  return (
    <div className="h-screen">
      <CustomTable
        dataSource={academicMasterValues}
        column={column}
        handleEditClick={handleEditClick}
        onClickAdd={onClickAdd}
        title={"Academic Master List"}
      />
    </div>
  );
}

export default AcademicMaster;
