export const column = [
  { key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80 },
  { key: '2', headerName: 'Admission Number', field: 'admissionNo', hide: false, width: 160 },
  { key: '2', headerName: 'Student Name', field: 'stName', hide: false, width: 180 },
  { key: '3', headerName: 'Admission Date', field: 'doa', hide: false, width: 150 },
  { key: '3', headerName: 'Admission Standard', field: 'admissionStandard', hide: false, width: 180 },
  { key: '4', headerName: 'Parent Name', field: 'parentNumber', hide: false, width: 180 },
  { key: '4', headerName: 'Parent Number', field: 'parentNumber', hide: false, width: 180 },
  { key: '13', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 180 },
];
