import React, {  } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { column } from './column';
import UseFeesTypeMaster from './UseFeesTypeMaster';


const FeesType = ({ setTopTitle }) => {
  
    setTopTitle('Fees Type');

  const {
    onClickAdd,
    handleEditClick,
    feesTypeValues
  } = UseFeesTypeMaster();

  return (
    <div className="h-screen pad-lg">
      <CustomTable
        dataSource={feesTypeValues}
        column={column}
        handleEditClick={handleEditClick}
        onClickAdd={onClickAdd}
        title={"Subject Master List"}
      />
    </div>
  );
}

export default FeesType;
