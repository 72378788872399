export const columnStandard = [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 180},
      {key: '2', headerName: 'Standard', field: 'standard', hide: false, width: 120},
      {key: '5', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120},
  ];
  
  export const columnSection = [
    {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 180},
    {key: '2', headerName: 'Section', field: 'section', hide: false, width: 120},
    {key: '5', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120},
];
