export const columnMenuMaster = [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80},
      {key: '2', headerName: 'Menu Name', field: 'name', hide: false, width: 250},
      {key: '3', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 200},
  ];
  
  export const columnMenuList = [
    {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 100},
    {key: '2', headerName: 'Menu Master Name', field: 'menuName', hide: false, width: 250},
    {key: '2', headerName: 'Menu List Name', field: 'name', hide: false, width: 250},
    {key: '3', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120},
];

export const columnMenuAccess = [
  {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 100},
  {key: '2', headerName: 'Country Name', field: 'countryName', hide: false, width: 250},
  {key: '3', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120},
];
