import { useMemo, useEffect, } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, } from "../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';

export default function useStandardMaster() {

  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const districtMasterValues = getData(state, apiKeys.districtMasterData, {});
  const stateMasterValues = getData(state, apiKeys.stateMasterData, {});
  const countryMasterValues = getData(state, apiKeys.countryMasterData, {});


  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.districtMasterData,
      method: apiMethods.GET,
      key: apiKeys.districtMasterData,
    }))
  }, [dispatch,]);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.stateMasterData,
      method: apiMethods.GET,
      key: apiKeys.stateMasterData,
    }))
  }, [dispatch,]);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.countryMasterData,
      method: apiMethods.GET,
      key: apiKeys.countryMasterData,
    }))
  }, [dispatch,]);

  const onClickAddStd = () => {
    navigate('/outletMaster/addForm');
  };

  const handleEditClickStd = (data) => {
    navigate('/outletMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  const onClickAddSec = () => {
    navigate('/outletMaster/addForm');
  };


  const handleEditClickSec = (data) => {
    navigate('/outletMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  return useMemo(() => ({
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    districtMasterValues,
    stateMasterValues,
    countryMasterValues
  }), [
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    districtMasterValues,
    stateMasterValues,
    countryMasterValues

  ]
  )

}