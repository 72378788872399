import { useMemo, useEffect, } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, } from "../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';

export default function useStandardMaster() {

  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const roleMasterValues = getData(state, apiKeys.roleMasterData, {});
  const designationMasterValues = getData(state, apiKeys.designationMasterData, {});


  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.roleMasterData,
      method: apiMethods.GET,
      key: apiKeys.roleMasterData,
    }))
  }, [dispatch,]);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.designationMasterData,
      method: apiMethods.GET,
      key: apiKeys.designationMasterData,
    }))
  }, [dispatch,]);

  const onClickAddStd = () => {
    navigate('/outletMaster/addForm');
  };

  const handleEditClickStd = (data) => {
    navigate('/outletMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  const onClickAddSec = () => {
    navigate('/outletMaster/addForm');
  };


  const handleEditClickSec = (data) => {
    navigate('/outletMaster/addForm', {
      state: { data, isEdit: true }
    });
  };

  return useMemo(() => ({
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    roleMasterValues,
    designationMasterValues
  }), [
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    roleMasterValues,
    designationMasterValues

  ]
  )

}